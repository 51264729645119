import ApiHelper from './index';

async function generateSegmentTrack(taskId, objectId, spinner = true) {
  return await ApiHelper.get(
    `organization/task/seg_and_track_annotate/${taskId}/${objectId}/`,
    spinner
  );
}

export default {
  generateSegmentTrack
};
