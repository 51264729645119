<template>
  <main-process />
</template>

<script>
import MainProcess from './MainProcess.vue';

export default {
  components: {
    MainProcess,
  },
};
</script>
