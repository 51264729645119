<template>
  <a-card
    :bodyStyle="{ height: '73vh', overflowY: 'auto' }"
    id="similar-steps-container"
  >
    <template #title>
      <div class="d-flex justify-content-between">
        <h6 class="my-auto">
          <strong>{{ taskName }}</strong>
        </h6>
        <a-button
          type="primary"
          ghost
          @click="handleAddSimilarStepsToGroup"
          :disabled="checkedSteps.length < 2"
          id="similar-steps-group-btn"
        >
          Add Similar Steps Group
        </a-button>
      </div>
    </template>

    <a-list
      :grid="{ gutter: 16, column: 1 }"
      size="small"
      :data-source="taskProcesses"
      :loading="isUpdatingSimilarSteps"
      :id="taskName + '-similar-steps-list'"
    >
      <template #renderItem="{ item: process }">
        <a-card
          size="small"
          class="mb-2"
          :bodyStyle="{
            overflowY: 'auto',
            overflowX: 'hidden',
            height: '55vh',
            padding: '0.5em !important',
          }"
        >
          <template #title>
            <h6 class="m-0">
              <span class="text-secondary">Process: </span>
              <strong>{{ process.name }}</strong>
            </h6>
          </template>

          <a-list
            :grid="{ gutter: 16, column: 1 }"
            size="small"
            :data-source="process.steps"
            :id="process.name + '-similar-steps'"
          >
            <template #renderItem="{ item: step }">
              <a-list-item :id="step + '-similar-single'">
                <a-card
                  size="small"
                  class="w-100"
                  :bodyStyle="{ padding: '0' }"
                >
                  <template #title>
                    <p class="m-0" :id="'similar-' + step + '-name'">
                      <span class="text-secondary">Step: </span>
                      <strong>{{ step.name }}</strong>
                    </p>
                  </template>
                  <a-list
                    size="small"
                    :data-source="step.substeps"
                    id="process-similar-sub-steps'"
                  >
                    <template #renderItem="{ item: substep }">
                      <a-list-item
                        class="d-flex p-1"
                        :style="{ background: '#F1F1F1' }"
                        :id="substep + '-similar-li'"
                      >
                        <span
                          class="w-75 px-2"
                          :class="{
                            'text-light': stepsToIndexMapping[substep]?.color,
                          }"
                          :style="{
                            background: stepsToIndexMapping[substep]?.color,
                            borderRadius: '5px',
                          }"
                          :id="substep + '-similar-name'"
                        >
                          {{ substep }}
                        </span>
                        <a-checkbox
                          :checked="checkedSteps.includes(substep)"
                          class="ml-auto"
                          @change="handleChange(substep)"
                          :id="substep + '-similar-checkbox'"
                        />
                      </a-list-item>
                    </template>
                  </a-list>
                </a-card>
              </a-list-item>
            </template>
          </a-list>
        </a-card>
      </template>
    </a-list>

    <div class="d-flex pt-2">
      <a-popconfirm
        title="Are you sure? You want to delete Similar Steps"
        ok-text="Yes"
        cancel-text="No"
        @confirm="updateSimilarSteps(JSON.stringify({}))"
        v-if="Object.keys(similarSteps).length > 0"
        :okButtonProps="{ id: 'delete-sim-steps-ok-option' }"
        :cancelButtonProps="{ id: 'delete-sim-steps-cancel-option' }"
      >
        <a-button
          danger
          :disabled="isUpdatingSimilarSteps"
          id="delete-similar-steps-btn"
        >
          Delete Similar Steps
        </a-button>
      </a-popconfirm>
      <a-space class="ml-auto">
        <a-button
          @click="updateSimilarStepsModalState(false)"
          :disabled="isUpdatingSimilarSteps"
          id="similar-steps-cancel-btn"
          >Cancel</a-button
        >

        <a-button
          type="primary"
          @click="handleSave"
          id="similar-steps-save-btn"
          :disabled="isUpdatingSimilarSteps"
        >
          Save
        </a-button>
      </a-space>
    </div>
  </a-card>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

const COLORS = [
  '#2f7ed8',
  '#0d233a',
  '#8bbc21',
  '#910000',
  '#1aadce',
  '#492970',
  '#f28f43',
  '#77a1e5',
  '#c42525',
  '#a6c96a',
];

export default {
  inject: ['toast'],
  data() {
    return {
      similarStepsList: [],
      currentGroup: 1,
      checkedSteps: [],
      stepsToIndexMapping: {},
    };
  },
  computed: {
    ...mapGetters([
      'taskProcesses',
      'similarSteps',
      'taskName',
      'isUpdatingSimilarSteps',
    ]),
  },
  mounted() {
    this.initializeState();
    if (Object.keys(this.similarSteps).length !== 0) this.populateState();
  },

  methods: {
    ...mapActions([
      'updateSimilarStepsModalState',
      'updateSimilarSteps',
      'handleDeleteSimilarSteps',
    ]),

    initializeState() {
      const [process] = this.taskProcesses;
      this.stepsToIndexMapping = process.steps
        .reduce((res, step) => [...res, ...step.substeps], [])
        .reduce((res, substep, index) => {
          res[substep] = { index, color: '', isMarked: false };
          return res;
        }, {});
    },

    populateState() {
      Object.values(this.similarSteps).forEach((value) => {
        this.checkedSteps = Object.keys(value);
        this.handleAddSimilarStepsToGroup();
      });
    },

    handleChange(substep) {
      if (!this.checkedSteps.includes(substep)) {
        this.checkedSteps.push(substep);
      } else {
        const index = this.checkedSteps.indexOf(substep);
        this.checkedSteps.splice(index, 1);
      }
    },

    ifConcurrentSteps() {
      const temp = this.checkedSteps
        .map((step) => this.stepsToIndexMapping[step]['index'])
        .sort((a, b) => a - b);
      const flag = temp.every((item, index, arr) => {
        if (index === 0) return true;
        return item - arr[index - 1] > 1;
      });

      !flag && this.toast.info('Concurrent steps cannot be marked!');
      return !flag;
    },

    ifStepsMarkedBefore() {
      const flag = this.checkedSteps.some(
        (step) => this.stepsToIndexMapping[step]['isMarked']
      );
      flag &&
        this.toast.info('Substep has already been marked in other group.');
      return flag;
    },

    handleAddSimilarStepsToGroup() {
      if (this.ifConcurrentSteps()) return;
      if (this.ifStepsMarkedBefore()) return;
      const tempGroup = [];
      this.checkedSteps.forEach((substep) => {
        tempGroup.push({
          stepIndex: this.stepsToIndexMapping[substep]['index'],
          stepName: substep,
        });
        this.stepsToIndexMapping[substep]['color'] = COLORS[this.currentGroup];
        this.stepsToIndexMapping[substep]['isMarked'] = true;
      });
      this.similarStepsList.push(tempGroup);
      this.checkedSteps = [];
      this.currentGroup++;
    },

    handleSave() {
      const similarStepsDict = this.similarStepsList.reduce(
        (res, group, index) => {
          res[`Similar Substep Group ${index + 1}`] = group.reduce(
            (res, { stepName, stepIndex }) => {
              res[stepName] = {
                stepIndex,
                stepName,
              };
              return res;
            },
            {}
          );
          return res;
        },
        {}
      );
      this.updateSimilarSteps(JSON.stringify(similarStepsDict));
    },
  },
};
</script>
<style scoped></style>
