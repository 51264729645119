<template>
  <div class="form" id="substep-translation">
    <a-typography-text class="label" :id="substep + '-name'">{{
      substep
    }}</a-typography-text>
    <a-input
      :value="getTranslatedValue"
      @input="handleSubstepChange"
      :id="substep + '-translate-input'"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  props: ['processIndex', 'stepIndex', 'substepIndex', 'substep'],
  emits: ['changeSubstep'],

  computed: {
    ...mapGetters(['substepsTranslation']),
    getTranslatedValue() {
      return (
        this.substepsTranslation(this.processIndex, this.stepIndex)[
          this.substepIndex
        ] || ''
      );
    },
  },
  methods: {
    ...mapActions(['updateSubstepTranslation']),
    handleSubstepChange(e) {
      const { value: substep } = e.target;
      const { substepIndex, stepIndex, processIndex } = this;
      this.updateSubstepTranslation({
        substep,
        substepIndex,
        stepIndex,
        processIndex,
      });
    },
  },
};
</script>
<style scoped>
.form {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.label {
  color: #34495e;
  font-size: 0.875em;
  text-align: end;
  margin-right: 10px;
  width: 40%;
}

.form-control {
  height: 30.44px;
  width: 65%;
  font-size: 0.875em;
}
</style>
