<template>
  <div class="p-1">
    <a-card
      v-for="{ key, paramType, title } in requiredStepsForCycle"
      :key="key"
      :title="title"
      :loading="isUpdatingTaskParams"
      size="small"
      hoverable
    >
      <div
        v-for="(process, processIndex) in taskProcesses"
        :key="process.name"
        class="d-flex my-1"
      >
        <a-typography class="w-25 text-center">
          Process: <strong>{{ process.name }}</strong>
        </a-typography>

        <a-select
          :value="taskParameters[paramType][key][processIndex] || []"
          :options="getStepOptions(processIndex)"
          class="w-75"
          mode="multiple"
          @change="(value) => setParamValue(key, value, processIndex)"
        />
      </div>
    </a-card>

    <!--  -->
    <a-card
      v-for="{ key, paramType, title } in cycleParams"
      :key="key"
      :title="title"
      :loading="isUpdatingTaskParams"
      size="small"
      hoverable
    >
      <a-input
        :value="taskParameters[paramType][key]"
        :name="key"
        @change="handleNumberChange"
      />
    </a-card>
    <!--  -->

    <sub-step-times :processes="taskProcesses" />
  </div>
</template>
<script>
import { deepClone } from 'src/utils/task';
import { mapActions, mapGetters } from 'vuex';
import { telemetryParams } from './config';
import SubStepTimes from './SubStepTimes.vue';
export default {
  components: {
    SubStepTimes,
  },
  setup() {
    const { requiredStepsForCycle, cycleParams } = telemetryParams;
    return { requiredStepsForCycle, cycleParams };
  },
  data() {
    return {
      substepList: [],
      processStepsOptions: {},
    };
  },

  computed: {
    ...mapGetters([
      'taskProcesses',
      'taskParameters',
      'isUpdatingTaskParams',
      'isParentOfVirtualTasks',
      'stepsToIndexMapping',
    ]),
  },

  beforeMount() {
    console.log('mounted', this.taskProcesses);
    this.substepList = this.taskProcesses.map((process) => {
      const allSubSteps = process.steps.reduce(
        (res, step) => [...res, ...step.substeps],
        []
      );

      return Object.keys(allSubSteps);
    });
  },

  methods: {
    ...mapActions(['setTaskParameters']),

    getStepOptions(processIndex) {
      if (this.processStepsOptions[processIndex]) {
        return this.processStepsOptions[processIndex];
      }
      const options = Object.entries(this.stepsToIndexMapping).map(
        ([label, value]) => ({
          label: label,
          value: value,
        })
      );
      this.processStepsOptions = {
        ...this.processStepsOptions,
        [processIndex]: options,
      };
      return options;
    },

    setParamValue(paramKey, value, processIndex = null) {
      const tempTaskParams = deepClone(this.taskParameters);
      if (processIndex !== null && processIndex >= 0) {
        tempTaskParams['telemetryParams'][paramKey][processIndex] = value;
      } else {
        tempTaskParams['telemetryParams'][paramKey] = value;
      }
      this.setTaskParameters(tempTaskParams);
    },

    handleNumberChange(e) {
      const { value, name } = e.target;
      if (Number.isNaN(Number(value))) return;
      this.setParamValue(name, value);
    },
  },
};
</script>
<style>
.ant-card {
  margin: 0.5em 0;
}
</style>
