export default [
  {
    value: 'crop',
    label: 'crop',
  },
  {
    value: 'color',
    label: 'color',
  },
  {
    value: 'blackout',
    label: 'blackout',
  },
  {
    value: 'affine',
    label: 'affine',
  },
];
