<template>
  <div
    id="deleteContextMenu"
    :style="{
      display: deleteShow ? 'initial' : 'none'
    }"
  >
    <button id="delete-button" @click="deletePoint">Delete</button>
  </div>
  <a-row
    class="h-100 d-flex justify-content-around align-items-center"
    :gutter="[24, 24]"
  >
    <a-col span="14" class="h-100 d-flex flex-column">
      <a-card
        hoverable
        :body-style="{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1
        }"
        size="small"
        class="annotation-image-card d-flex flex-column flex-grow-1"
      >
        <a-spin
          v-if="imageLoading"
          :indicator="indicator"
          class="m-auto"
          size="large"
        />

        <a-result
          v-else-if="!imageLoading && !depthFrame"
          id="not-found-plane-image"
          class="m-auto"
        >
          <template #title>
            <h6>
              Please press <b>Generate Depth Frame</b> button to create plane
              regions.
            </h6>
          </template>
        </a-result>

        <template #title>
          <div
            class="d-flex"
            style=" justify-content: space-between;  margin-top: 5px; "
          >
            <div class="d-flex">
              <a-radio-group v-model:value="selectedOperation" size="medium">
                <a-radio-button value="generate" style="width: max-content;"
                  >Generate Depth Frame</a-radio-button
                >
                <a-radio-button value="reset" style="width: max-content;"
                  >Reset Depth Frame</a-radio-button
                >
              </a-radio-group>
            </div>
            <div class="d-flex">
              <span
                class="color-selector ml-auto"
                :style="`background:${textColor}`"
                :disabled="imageLoading"
                :class="{ greyBorder: textColor === 'white' }"
                @click="changeLabelColor"
              />
              <span
                class="color-selector"
                :style="
                  `background:${textColor === 'black' ? 'white' : 'black'}`
                "
                :disabled="imageLoading"
                :class="{ greyBorder: textColor === 'black' }"
                @click="changeLabelColor"
              />
            </div>

            <div>
              <p style="color: red; margin-right: 10vw; font-size: small;">
                You cannot place points inside grey shaded area.
              </p>
            </div>
          </div>
        </template>

        <template v-if="!imageLoading && image" #cover>
          <v-stage
            id="plane-define-stage"
            :key="groups"
            ref="stage"
            :config="stageSize"
            @click="handleStageClick"
            @mousedown="handleStageMouseDown"
            @touchstart="handleStageMouseDown"
            @contextmenu="disableWebMenu"
          >
            <v-layer id="region-define-canvas-layer" ref="layer">
              <v-image
                id="region-define-canvas-image"
                ref="image"
                :config="imageConfig"
              />
              <v-group
                v-for="(circle, index1) in groups.circles"
                :key="index1"
                @contextmenu="handleDeleteMenuShow"
              >
                <v-circle
                  v-for="(point, index2) in circle"
                  :key="index2"
                  :config="point"
                  :draggable="true"
                  :dragBoundFunc="pos => dragBoundFunc(pos, index1, index2)"
                  @dragend="handleTransformCircle"
                />
              </v-group>
              <v-transformer id="region-define-transformer" ref="transformer" />
            </v-layer>
          </v-stage>
        </template>
      </a-card>
    </a-col>

    <a-col span="8" class="h-100 d-flex flex-column">
      <a-list
        id="marked-region-obj-list"
        bordered
        class="static-objects-list"
        :loading="{ spinning: !isObjectFetched && image, size: 'large' }"
        item-layout="horizontal"
        :data-source="planeObjectsList"
        size="small"
      >
        <template #header>
          <span>Defined Planes</span>
        </template>
        <template #renderItem="{ item, index }">
          <a-list-item :id="'region-obj-' + index" style="cursor: pointer">
            <a-list-item-meta>
              <template #title>
                <a-row>
                  <a-col span="8">
                    <template
                      style="margin-left: 10px"
                      v-if="item.equation && item.equation?.length > 3"
                    >
                      <CheckOutlined
                        style="color: green; padding: 10px; fontsize: 18px"
                      />
                    </template>

                    <template
                      style="margin-left: 10px"
                      v-if="
                        item?.equation === null || item.equation?.length < 3
                      "
                    >
                      <ExclamationOutlined style="color: red; padding: 10px" />
                    </template>

                    <span
                      :id="'plane-name-' + item.name"
                      :class="{ 'disable-click': existedObject[item.name] }"
                    >
                      {{ item.name }}
                    </span>
                  </a-col>

                  <a-col span="16">
                    <div
                      class="d-flex align-items-center justify-content-end"
                      style="gap: 16px"
                    >
                      <input
                        type="color"
                        v-model="item.points.plane_color"
                        @change="handleChangePlaneColor(item)"
                      />

                      <h6 class="mb-0">
                        {{ groups?.circles[item.name]?.length }}
                      </h6>

                      <a-space class="d-flex">
                        <a-tooltip>
                          <template #title>Add Point</template>
                          <a-button
                            shape="circle"
                            @click="setNewObjectConfigToDraw(item)"
                          >
                            <template #icon>
                              <PlusOutlined />
                            </template>
                          </a-button>
                        </a-tooltip>

                        <a-tooltip>
                          <template #title>Remove Point</template>
                          <a-button
                            shape="circle"
                            @click="deleteLastPoint(item.name)"
                          >
                            <template #icon>
                              <MinusOutlined />
                            </template>
                          </a-button>
                        </a-tooltip>

                        <a-tooltip>
                          <template #title>Calculate Plane Equation</template>
                          <a-button
                            shape="circle"
                            @click="calculateEquation(item)"
                          >
                            <template #icon>
                              <CalculatorOutlined
                                v-if="
                                  calculatePlanceEquLoader[item.id] == null ||
                                    !calculatePlanceEquLoader[item.id]
                                "
                              />
                              <a-spin
                                size="small"
                                style="
                                  display: flex;
                                  align-items: center;
                                  justify-content: center;
                                "
                                v-else
                              />
                            </template>
                          </a-button>
                        </a-tooltip>

                        <a-tooltip>
                          <template #title>Reset Plane Equation</template>
                          <a-button
                            shape="circle"
                            @click="resetPlaneEquation(item)"
                          >
                            <template #icon>
                              <RedoOutlined />
                            </template>
                          </a-button>
                        </a-tooltip>

                        <a-switch
                          v-model:checked="overlayCheck[item.id]"
                          :disabled="!item.equation || item.equation.length < 3"
                          :loading="overlayCheck[item.id] && overlayLoading"
                          @change="
                            setImage(
                              overlayCheck[item.id] == true
                                ? 'overlay'
                                : 'depth',
                              item.id
                            )
                          "
                        />
                      </a-space>
                    </div>
                  </a-col>
                </a-row>
              </template>
            </a-list-item-meta>
          </a-list-item>
        </template>
      </a-list>
    </a-col>
  </a-row>
</template>
<script>
import { colorsConfig } from '@/utils/detector';
import { parseStringCoordsToObj } from '../../LabelData/ObjectAnnotation/helpers';
import * as OBJECT from '../../LabelData/ObjectAnnotation/object';
import { mapActions, mapGetters } from 'vuex';
import objectsMarkingMixin from '@/mixins/objectsMarking';
import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  EditOutlined,
  FontSizeOutlined,
  BorderOutlined,
  PlusOutlined,
  MinusOutlined,
  RiseOutlined,
  CheckOutlined,
  ExclamationOutlined,
  CalculatorOutlined,
  RedoOutlined,
  SettingOutlined
} from '@ant-design/icons-vue';
import TaskObjectService from '@/services/taskObjectsMarking';
import DeviceService from 'src/services/device';
import DepthRegionService from 'src/services/3dPlaneEquation';
import S3Service from 'src/services/s3';

export default {
  components: {
    DeleteOutlined,
    ExclamationCircleOutlined,
    EditOutlined,
    FontSizeOutlined,
    BorderOutlined,
    PlusOutlined,
    MinusOutlined,
    RiseOutlined,
    ExclamationOutlined,
    CheckOutlined,
    CalculatorOutlined,
    RedoOutlined,
    SettingOutlined
  },

  mixins: [objectsMarkingMixin],

  inject: ['toast'],
  setup: () => ({
    OBJECT
  }),

  data() {
    return {
      overlayImages: {},
      overlayCheck: {},
      depthFrame: null,
      depthFrameUri: [],
      imageLoading: true,
      image: null,
      objectList: [],
      overlayLoading: false,
      selectedCircleId: '',
      textColor: 'white',
      selectedCircleKey: '',
      selectedOperation: '',
      calculatePlanceEquLoader: {},
      annotationSaveLoading: false,
      imageDimensions: { width: 0, height: 0 },
      stageSize: { width: 640, height: 480 },
      isObjectFetched: false,
      zeroDepthPixel: {},
      prevPoints: { x: 320, y: 240 },
      prevPointType: null,
      groups: {
        circles: {},
        labels: {}
      },
      existedObject: {},
      deleteShow: false
    };
  },

  computed: {
    ...mapGetters([
      'selectedTask',
      'taskPlaneObjectsList',
      'taskObjects',
      'planeObjectsList',
      'organization'
    ]),

    imageConfig() {
      return {
        image: this.image,
        name: 'currentImage',
        ...this.stageSize
      };
    }
  },

  watch: {
    groups() {
      this.updateExistedObjects();
    },
    selectedOperation(val) {
      if (val == 'generate') {
        this.generateDepthFrame();
      } else if (val == 'reset') {
        this.resetDepthFrame();
      }
    },
    async textColor(newColor) {
      this.imageLoading = true;

      if (newColor == 'black') {
        this.depthFrame = this.depthFrameUri[1];
      } else {
        this.depthFrame = this.depthFrameUri[0];
      }
      await this.setImage('depth', null);
      this.imageLoading = false;
    }
  },

  async beforeMount() {
    this.isObjectFetched = false;
    let response = await this.getZeroDepthFrame();
    this.zeroDepthPixel = response?.zero_depth_pixel;
    this.depthFrameUri = [
      response?.zero_depth_pixel_white_image_path,
      response?.zero_depth_pixel_black_image_path
    ];
    this.depthFrame = this.depthFrameUri[0];
    await this.setImage('depth', null);
    await this.updateImageConfig();
    await this.renderExistingStaticObjectList();
    this.isObjectFetched = true;
    this.planeObjectsList.forEach(item => {
      this.overlayCheck[item.id] = false;
    });
  },

  unmounted() {
    this.zeroDepthPixel = {};
    this.groups = {
      rectangles: {},
      labels: {}
    };
  },

  methods: {
    ...mapActions([
      'getTaskPlaneObjectsList',
      'setTaskImageMarkingUrl',
      'addPlaneObject'
    ]),

    disableWebMenu(e) {
      e.evt?.preventDefault();
      return false;
    },

    changeLabelColor() {
      if (this.textColor === 'white') this.textColor = 'black';
      else this.textColor = 'white';
    },

    checkPointInsideGreyRegion(width, height, pointx, pointy) {
      let x = Math.round((pointx / width) * 640);
      let y = Math.round((pointy / height) * 480);
      let isZeroRegion = false;

      if (this.zeroDepthPixel.hasOwnProperty(x)) {
        let val = this.zeroDepthPixel[x];
        if (val.hasOwnProperty(y)) {
          isZeroRegion = true;
        }
      }

      if (pointx > width || pointy > height) {
        isZeroRegion = true;
      }
      return isZeroRegion;
    },
    checkIfpointOverlapEachOther(width, height, pointx, pointy) {
      let checkPointsOverlay = false;
      let x = Math.round((pointx / width) * 640);
      let y = Math.round((pointy / height) * 480);

      for (const [key1, plane] of Object.entries(this.groups.circles)) {
        for (const [key2, point] of Object.entries(plane)) {
          let ptx = Math.round((point.x / width) * 640);
          let pty = Math.round((point.y / height) * 480);
          if (Math.abs(x - ptx) < 10 && Math.abs(y - pty) < 10) {
            checkPointsOverlay = true;
            return checkPointsOverlay;
          }
        }
      }
      return checkPointsOverlay;
    },
    dragBoundFunc(pos, plainName, circleIndex) {
      let currentPointType = plainName + circleIndex;
      const container = this.$refs.stage;
      let pointx = pos.x;
      let pointy = pos.y;
      let valx = 0;
      let valy = 0;
      if (
        this.checkPointInsideGreyRegion(
          container.oldProps.width,
          container.oldProps.height,
          pos.x,
          pos.y
        ) ||
        this.checkIfpointOverlapEachOther(
          container.oldProps.width,
          container.oldProps.height,
          pos.x,
          pos.y
        )
      ) {
        if (
          this.prevPointType == null ||
          this.prevPointType != currentPointType
        ) {
          valx = pos.x;
          valy = pos.y;
          this.prevPoints.x = valx;
          this.prevPoints.y = valxy;
        } else {
          valx = this.prevPoints.x;
          valy = this.prevPoints.y;
        }
      } else {
        this.prevPoints.x = pos.x;
        this.prevPoints.y = pos.y;
        this.prevPointType = currentPointType;
        const minX = 10;
        const minY = 10;
        const maxX = Math.abs(container.oldProps.width - 10);
        const maxY = Math.abs(container.oldProps.height - 10);

        valx = Math.min(Math.max(minX, pointx), maxX);
        valy = Math.min(Math.max(minY, pointy), maxY);
      }
      return {
        x: valx,
        y: valy
      };
    },

    // async getDepthFrame() {
    //   try {
    //     var [error, data] = await DepthRegionService.getDepthVideoFrames(
    //       this.selectedTask
    //     );
    //     if (error) {
    //       this.toast.error('Depth frame not found');
    //       return null;
    //     }
    //   } catch (e) {
    //     this.toast.error('Depth frame not found');
    //     return null;
    //   }

    //   return data;
    // },

    async getZeroDepthFrame() {
      try {
        var [error, data] = await DepthRegionService.getZeroDepthVideoFrames(
          this.selectedTask
        );
        if (error) {
          this.handleZeroDepthFrameError(error);
          return null;
        }
      } catch (error) {
        return null;
      }
      return data;
    },

    handleZeroDepthFrameError(error) {
      this.imageLoading = false;
      let errMessage =
        typeof error.response !== 'undefined'
          ? error.response.data.message
          : error.message;
      const msg =
        errMessage === 'undefined' || errMessage.length == 0
          ? 'Depth frame not found'
          : errMessage;
      if (typeof msg === 'string') this.toast.error(msg);
    },

    async convertDepthFrameToUrl(frameType, planeId) {
      let imageUrl = '';
      if (frameType == 'depth') {
        const [error2, url] = await S3Service.generatePresignedUrl(
          `${this.organization}-training`,
          this.depthFrame
        );
        if (error2) {
          console.log('Error:', error2);
          return;
        }
        imageUrl = url;
      } else {
        if (
          planeId == null ||
          this.overlayImages.hasOwnProperty(planeId) == false ||
          this.overlayImages[planeId] == null
        ) {
          const [error, data] = await DepthRegionService.getOverlayImage(
            this.selectedTask,
            planeId
          );
          if (error) {
            this.toast.error(
              'Overlay image not found.Please calculate the plane equation first'
            );
            this.overlayCheck[planeId] = false;
            return;
          }
          this.overlayImages[planeId] = data.visualize_depth_path;
        }
        const [error3, url] = await S3Service.generatePresignedUrl(
          `${this.organization}-training`,
          this.overlayImages[planeId]
        );
        if (error3) {
          console.log('Error:', error3);
          return;
        }
        imageUrl = url;
      }
      return imageUrl;
    },
    async resetDepthFrame() {
      let payload = {};
      this.planeObjectsList.forEach(item => {
        payload[item.id] = [];
        for (let i = 0; i < 3; i++) {
          let pointx = this.getRandomInt(160, 480);
          let pointy = this.getRandomInt(120, 360);
          payload[item.id].push([pointx, pointy]);
        }
      });
      const [error, data] = await DepthRegionService.resetDepthVideoFrames(
        this.selectedTask,
        { points: payload }
      );
      this.selectedOperation = '';

      if (error) {
        this.toast.error('Unable to reset depth frame');
        return;
      }
      await this.renderExistingStaticObjectList();
      this.planeObjectsList.forEach(item => {
        this.overlayCheck[item.id] = false;
      });
      this.toast.success('Depth frame reset successfully');
    },
    async generateDepthFrame() {
      this.imageLoading = true;
      this.isObjectFetched = false;
      const [error, data] = await DepthRegionService.generateDepthVideoFrames(
        this.selectedTask,
        {}
      );
      this.selectedOperation = '';

      if (error) {
        this.toast.error('Unable to generate depth frame');
        return;
      }

      let response = await this.getZeroDepthFrame();
      this.zeroDepthPixel = response?.zero_depth_pixel;
      this.depthFrameUri = [
        response?.zero_depth_pixel_white_image_path,
        response?.zero_depth_pixel_black_image_path
      ];
      this.depthFrame = this.depthFrameUri[0];
      this.textColor = 'white';
      await this.resetStateOnGenerateDepthFrame();
      await this.setImage('depth', null);
      await this.updateImageConfig();

      await this.renderExistingStaticObjectList();
      this.isObjectFetched = true;
      this.planeObjectsList.forEach(item => {
        this.overlayCheck[item.id] = false;
      });

      this.imageLoading = false;

      this.toast.success('Successfully generated depth frame');
    },

    async resetStateOnGenerateDepthFrame() {
      await this.getTaskPlaneObjectsList();
      this.planeObjectsList.forEach(item => {
        this.overlayCheck[item.id] = false;
      });
    },

    async calculateEquation(selectedPlane) {
      if (this.imageLoading || !this.depthFrame) {
        this.toast.error('Image not loaded properly');
        return;
      }
      this.calculatePlanceEquLoader[selectedPlane.id] = true;
      let payload = {
        task: selectedPlane.task
      };
      const [error, data] = await DepthRegionService.calculatePlaneEquation(
        selectedPlane.id,
        payload
      );
      if (error) {
        let message =
          typeof error.response !== 'undefined'
            ? error.response.data.message
            : error.message;
        this.toast.error(message);
        this.calculatePlanceEquLoader[selectedPlane.id] = false;
        return;
      }

      if (data.data.depth_video.path_to_overlay_image != null) {
        this.overlayImages[selectedPlane.id] =
          data.data.depth_video.path_to_overlay_image +
          '-' +
          selectedPlane.id +
          '.jpg';
        console.log(this.overlayImages[selectedPlane.id]);
      }
      this.toast.success('Plane Equation Calculated Successfully!');
      await this.renderExistingStaticObjectList();
      this.calculatePlanceEquLoader[selectedPlane.id] = false;
    },

    updateExistedObjects() {
      const { labels } = this.groups;
      this.existedObject = Object.values(labels).reduce((res, el) => {
        res[el.text] = true;
        return res;
      }, {});
    },

    // async fetchDevices() {
    //   const [error, data] = await DeviceService.fetchAllDevicesOfOrg(
    //     this.organization,
    //     false,
    //     { depth_cam_configured: true }
    //   );
    //   if (error) {
    //     console.log(error);
    //     return;
    //   }
    //   this.deviceList = data.filter((d) => d.Type !== "departmental device");
    //   console.log("Devices", this.deviceList);
    // },

    updateImageConfig() {
      return new Promise(async resolve => {
        const canvas = await this.getCanvasElement();
        const { width, height } = canvas.getBoundingClientRect();
        this.imageDimensions = {
          width,
          height
        };
        this.stageSize = {
          width,
          height
        };
        resolve();
      });
    },

    getCanvasElement() {
      return new Promise(resolve => {
        const interval = setInterval(() => {
          const canvas = document.getElementsByTagName('canvas')[0];
          if (canvas) {
            clearInterval(interval);
            resolve(canvas);
          }
        }, 1000);
      });
    },

    isObjectExist(name) {
      const { labels } = this.groups;
      const isExist = Object.values(labels).some(o => o.text === name);
      return isExist;
    },

    // set image
    setImage(imageType, planeId) {
      return new Promise(async resolve => {
        if (planeId != null || planeId != undefined) {
          for (const [key, value] of Object.entries(this.overlayCheck)) {
            if (key != planeId) {
              this.overlayCheck[key] = false;
            }
          }
        }

        if (!this.depthFrame) {
          return;
        }
        let url = null;
        try {
          this.overlayLoading = true;
          url = await this.convertDepthFrameToUrl(imageType, planeId);
        } catch (e) {
          this.overlayLoading = false;
          return;
        }
        const img = new window.Image();
        const imgUrl = url?.presigned_url;
        if (!imgUrl) {
          this.imageLoading = false;
          return;
        }
        img.src = imgUrl;
        img.onload = () => {
          this.image = img;
          this.image.width = img.naturalWidth;
          this.image.height = img.naturalHeight;
          this.imageLoading = false;
          this.overlayLoading = false;
        };
        img.onerror = () => {
          this.imageLoading = false;
          this.overlayLoading = false;
        };
        resolve();
      });
    },
    getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min)) + min; //The maximum is exclusive and the minimum is inclusive
    },
    // add new object in groups
    async setNewObjectConfigToDraw(planeObj) {
      if (this.imageLoading || !this.depthFrame) {
        this.toast.error('Image not loaded properly');
        return;
      }
      let plainPoints = planeObj.points;
      if (!plainPoints['plane_points']) return;
      let newCircleName =
        planeObj.name + '-' + plainPoints['plane_points'].length;

      let pointx = this.getRandomInt(160, 480);
      let pointy = this.getRandomInt(120, 360);

      plainPoints['plane_points'].push([pointx, pointy]);
      planeObj.points = JSON.stringify(plainPoints);
      await this.addPlaneObject(planeObj);
      planeObj.points = plainPoints;
      this.groups = this.addCircleFromObjects(
        this.groups,
        planeObj.name,
        [pointx, pointy],
        newCircleName
      );
    },

    handleDeleteMenuShow(e) {
      e.evt?.preventDefault();
      if (!e.target.hasOwnProperty('parent')) {
        return;
      }
      const pos = this.$refs.stage.getStage().getPointerPosition();
      const deleteContextMenu = document.getElementById('deleteContextMenu');
      const name = e.target.name();
      const stage = document.getElementById('plane-define-stage');
      if (name && name !== 'currentImage') {
        const { top, left } = stage.getBoundingClientRect();
        const { width } = deleteContextMenu.getBoundingClientRect();
        deleteContextMenu.style.top = top + pos.y + 4 + 'px';
        deleteContextMenu.style.left = `${left + pos.x - width}px`;
        this.deleteShow = true;

        const circle = this.groups.circles[name];
        if (circle) {
          this.selectedCircleKey = name;
          this.selectedCircleId = name;
        }
      }
    },

    handleStageClick(e) {
      e.evt?.preventDefault();
      if (e.evt?.button === 2) return;
      const deleteContextMenu = document.getElementById('deleteContextMenu');
      if (deleteContextMenu.style.display === 'initial') {
        this.selectedCircleId = null;
        this.deleteShow = false;
      }
    },

    // set Transformer when click on stage
    async handleStageMouseDown(e) {
      if (!e.target.hasOwnProperty('parent')) {
        return;
      }
      const clickedOnTransformer =
        e.target.getParent().className === 'Transformer';
      if (clickedOnTransformer) {
        return;
      }
      const pointName = e.target.name();
      const planeName = pointName.split('-')[0];
      const circles = this.groups.circles[planeName];

      if (circles != null || circles != undefined) {
        this.selectedCircleKey = planeName;
        this.selectedCircleId = pointName;
      } else {
        this.selectedCircleKey = '';
        this.selectedCircleId = '';
      }

      // if (!e.target.hasOwnProperty('parent')) return;

      // clicked on stage - clear selection
      if (!e.target?.getStage) return;
      if (e.target === e.target.getStage()) {
        return;
      }
    },

    async updateCirclePoints(pointname) {
      const planeName = pointname.split('-')[0];
      const circles = this.groups.circles[planeName];
      let planeData;
      this.planeObjectsList.forEach(plane => {
        if (plane.name == planeName) {
          planeData = plane;
        }
      });

      let updatedCircle;
      circles?.forEach(circle => {
        if (circle.name == pointname) {
          updatedCircle = circle;
        }
      });

      var plainPoints = planeData.points;
      let pointx = Math.round((updatedCircle.x * 640) / this.stageSize.width);
      let pointy = Math.round((updatedCircle.y * 480) / this.stageSize.height);

      plainPoints.plane_points.splice(pointname.split('-')[1], 1, [
        pointx,
        pointy
      ]);

      planeData.points = JSON.stringify(plainPoints);
      await this.addPlaneObject(planeData);
      planeData.points = plainPoints;
    },

    // display existing objects
    renderExistingStaticObjectList() {
      return new Promise(async (resolve, reject) => {
        await this.getTaskPlaneObjectsList();
        const existGroup = {
          circles: {},
          labels: {}
        };
        this.planeObjectsList.forEach((obj, index) => {
          obj.points = JSON.parse(obj.points);
          obj.points.plane_color =
            obj.points.plane_color || colorsConfig[index];
          const { plane_points, label } = this.createCircleLabelGroup(obj);
          existGroup.circles[obj.name] = plane_points;
        });
        this.groups = existGroup;
        resolve();
      });
    },

    async handleChangePlaneColor(planeObj) {
      this.groups.circles[planeObj.name].forEach(obj => {
        obj.fill = planeObj.points.plane_color;
      });
      let plainPoints = planeObj.points;
      planeObj.points = JSON.stringify(plainPoints);
      await this.addPlaneObject(planeObj);
      planeObj.points = plainPoints;
    },

    getNewlyAddedObjs() {
      return this.taskObjects.filter(t => {
        let obj_exists = false;
        this.taskPlaneObjectsList.filter(o => {
          if (o.name === t.name) {
            obj_exists = true;
          }
        });
        return !obj_exists && t.is_3d;
      });
    },

    getObjectListFromState() {
      const new_regions = this.getNewlyAddedObjs().map(o => {
        return {
          ...o,
          cordinates_of_static_object: parseStringCoordsToObj(
            o.cordinates_of_static_object
          ),
          actual_name: o.name
        };
      });

      let k = this.taskPlaneObjectsList
        .filter(t => t.step_id === null)
        .map(o => {
          return {
            ...o,
            cordinates_of_static_object: parseStringCoordsToObj(
              o.cordinates_of_static_object
            ),
            actual_name: o.name
          };
        });

      k = k.concat(new_regions);
      return k;
    },

    async resetPlaneEquation(planeObj) {
      planeObj.equation = null;
      let plainPoints = planeObj.points;
      planeObj.points = JSON.stringify(plainPoints);
      await this.addPlaneObject(planeObj);
      planeObj.points = plainPoints;
    },

    // save objects Marking
    async saveAnnotation() {
      this.annotationSaveLoading = true;
      let annotations = this.getAnnotations();
      let staticObjects = annotations.map(OBJECT.nestCoordinates);
      let updatedAnnotations = this.getUpdatedObjects(staticObjects);
      console.log('updatedAnnotations', updatedAnnotations);
      if (updatedAnnotations.length > 0) {
        await this.updateObjects(updatedAnnotations);
      }

      this.setStateOnSave();
      this.toast.success('Regions Saved Successfully!');
    },

    // getUpdatedObjects(staticObjects) {
    //   const updatedObjects = staticObjects
    //     .filter((o) => this.updatedStaticObjectIds?.has(o.id))
    //     ?.map((obj) => OBJECT.mapStaticObjects(obj, this.selectedTask, false));
    //   const deletedObjects = this.getDeletedObjects()?.map((ob) => ({
    //     ...ob,
    //     cordinates_of_static_object: null,
    //   }));
    //   return [...updatedObjects, ...deletedObjects];
    // },

    updateObjects(data) {
      return new Promise(async resolve => {
        const payload = JSON.stringify(data);
        console.log('update payload ->', payload);
        const [error] = await TaskObjectService.updateRegions(payload);
        if (error) {
          console.log('update err ->', error);
          this.toast.error('Error occurred in updating objects');
        }
        resolve();
      });
    },

    async setStateOnSave() {
      this.annotationSaveLoading = false;
      await this.getTaskPlaneObjectsList();
    },

    async deleteLastPoint(planeName) {
      var planeData = this.planeObjectsList.find(
        plane => plane.name == planeName
      );
      var plainPoints = planeData.points;
      if (plainPoints.plane_points.length <= 3) {
        this.toast.error('Plane cannot have less then 3 points!');
        return;
      }
      plainPoints.plane_points.splice(plainPoints.plane_points.length - 1, 1);
      this.groups.circles[planeName].splice(
        this.groups.circles[planeName].length - 1,
        1
      );
      planeData.points = JSON.stringify(plainPoints);
      await this.addPlaneObject(planeData);
      planeData.points = plainPoints;
    },

    // remove Object
    async deletePoint() {
      if (this.selectedCircleId == '' || this.selectedCircleId == undefined)
        return;

      var indexToDelete = this.selectedCircleId.split('-')[1];

      var planeData = this.planeObjectsList.find(
        plane => plane.name == this.selectedCircleKey
      );

      var plainPoints = planeData.points;
      if (plainPoints.plane_points.length <= 3) {
        this.toast.error('Plane cannot have less then 3 points!');
        return;
      }
      if (indexToDelete > -1) {
        plainPoints.plane_points.splice(indexToDelete, 1);
        this.groups.circles[this.selectedCircleKey].splice(indexToDelete, 1);
        // sorting it according to ids
        let size = this.groups.circles[this.selectedCircleKey].length;
        for (let i = 0; i < size; i++) {
          let name = this.groups.circles[this.selectedCircleKey][i][
            'name'
          ].split('-')[0];
          this.groups.circles[this.selectedCircleKey][i]['name'] =
            name + '-' + i;
        }
      }
      planeData.points = JSON.stringify(plainPoints);
      this.deleteShow = false;
      await this.addPlaneObject(planeData);
      planeData.points = plainPoints;
    }
  }
};
</script>

<style>
.annotation-image-card > .ant-card-cover {
  padding: 1em !important;
  flex-grow: 1;
  display: flex !important;
}

.annotation-image-card > .ant-card-cover > div {
  display: flex !important;
}

.annotation-image-card > .ant-card-cover > div > .konvajs-content {
  width: unset !important;
  height: unset !important;
  flex-grow: 1 !important;
  display: flex;
}

.annotation-image-card > .ant-card-cover > div > .konvajs-content > canvas {
  border: 1.5px solid #d9d9d9 !important;
  border-radius: 5px;
  flex-grow: 1;
  width: 100% !important;
  height: 100% !important;
}

.static-objects-list {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.static-objects-list > .ant-list-header {
  background: lightgray !important;
  font-weight: 600 !important;
}

.static-objects-list > .ant-spin-nested-loading {
  flex-grow: 1;
  height: 1px;
  overflow-y: auto;
}

.greenCheck {
  padding: 10px;
  background-color: green;
  display: inline-block;
}

.color-box {
  width: 20px;
  height: 10px;
  display: inline-block;
  background: red;
}

.greenDot {
  height: 10px;
  width: 10px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
}

.disable-click {
  pointer-events: none;
  color: lightgray;
}
</style>
