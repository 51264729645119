<template>
  <a-modal
    id="add-translation-modal"
    :visible="isTranslation"
    centered
    destroy-on-close
    :closable="false"
    :footer="null"
    :title="
      (Object.keys(similarSteps).length === 0 ? 'Add' : 'Update') +
        ' Translation'
    "
    width="60vw"
    @cancel="cancelTranslation"
  >
    <AddTranslation />
  </a-modal>

  <a-modal
    id="similar-steps-modal"
    :visible="showSimilarStepsModal"
    centered
    destroy-on-close
    :closable="false"
    :footer="null"
    :title="(!isTranslationEdit ? 'Add' : 'Update') + ' Similar Steps'"
    width="60vw"
  >
    <SimilarSteps />
  </a-modal>

  <a-modal
    id="projector-region-modal"
    :visible="showProjectorRegionModal"
    centered
    destroy-on-close
    :closable="true"
    :footer="null"
    :title="'IoT Plugins'"
    width="60vw"
    @cancel="closeProjectorModal"
  >
    <Devices />
  </a-modal>

  <a-modal
    id="step-time-modal"
    :visible="showStepTimeModal"
    centered
    destroy-on-close
    :closable="false"
    :footer="null"
    :title="`${isStepTimeExist ? 'Update' : 'Add'} Standard Time`"
    width="60vw"
  >
    <StepTime />
  </a-modal>

  <a-modal
    id="task-parameters-modal"
    :visible="showTaskParamsModal"
    centered
    width="50vw"
    destroy-on-close
    :closable="false"
    title="Task Parameters"
    :footer="null"
  >
    <TaskParameters />
  </a-modal>

  <a-modal
    id="mark-static-objects-modal"
    v-model:visible="showRegionDefinitionModal"
    centered
    width="80vw"
    destroy-on-close
    title="Define Regions / Static Objects"
    :closable="false"
    :footer="null"
  >
    <RegionDefinition />
  </a-modal>

  <!-- <a-modal
    v-model:visible="showAnnotationObjectsModal"
    centered
    width="60vw"
    :closable="false"
    destroy-on-close
    title="Add Annotation Object(s)"
    :footer="null"
  >
    <ObjectAnnotation />
  </a-modal>  -->

  <a-row :gutter="[8, 8]" class="p-4 h-100">
    <a-col :xs="24" :sm="24" :md="24" :lg="12" class="d-flex flex-column">
      <AddNewTask @onCancelEditTask="resetProcessState" />
    </a-col>

    <a-col :xs="24" :sm="24" :md="24" :lg="12" class="d-flex flex-column">
      <TaskList />
    </a-col>
  </a-row>
</template>

<script>
import TaskList from './Task/TaskList.vue';
import AddNewTask from './Task/AddNewTask.vue';
import AddTranslation from './Translation/AddTranslation.vue';
import SimilarSteps from './SimilarSteps/SimilarSteps.vue';
import Devices from './ProjectorRegions/Devices.vue';
import StepTime from './StepTime/StepTime.vue';
import TaskParameters from './TaskParameters/TaskParameters.vue';
import RegionDefinition from './RegionDefinition/RegionDefinition.vue';
import { mapActions, mapGetters } from 'vuex';
import types from 'src/store/mutation-types';

export default {
  components: {
    TaskParameters,
    StepTime,
    TaskList,
    AddNewTask,
    SimilarSteps,
    AddTranslation,
    RegionDefinition,
    Devices
  },

  data() {
    return {
      showPolicyModal: false,
      types
    };
  },

  computed: {
    ...mapGetters([
      'allTasks',
      'editModes',
      'isTaskEdit',
      'organization',
      'similarSteps',
      'selectedTask',
      'isTranslation',
      'currentEditMode',
      'isStepTimeExist',
      'isPolicyAccepted',
      'showStepTimeModal',
      'isTranslationEdit',
      'showTaskParamsModal',
      'showSimilarStepsModal',
      'showRegionDefinitionModal',
      'showAssociateRegionModal',
      'showModelVersionsModal',
      'showAnnotationObjectsModal',
      'showProjectorRegionModal',
      'negativeSteps',
      'optionalSteps'
    ])
  },

  created() {
    this.getAllTasks();
    if ([false, 'false'].includes(this.isPolicyAccepted))
      this.showPolicyModal = true;
  },

  methods: {
    ...mapActions([
      'showModal',
      'userLogout',
      'setTaskId',
      'addNewTask',
      'getAllTasks',
      'resetProcessState',
      'setProcesses',
      'updateSingleTask',
      'deleteSingleTask',
      'fetchTaskDetails',
      'cancelTranslation',
      'closeProjectorModal',
      'handleAcceptPolicy',
      'setProcessesTranslation',
      'showModal'
    ]),

    resetStates() {
      this.setProcesses([]);
      this.setProcessesTranslation([]);
      this.cancelTranslation();
    }
  }
};
</script>
<style>
.color-box {
  width: 20px;
  height: 10px;
  display: inline-block;
}

.ant-modal-body {
  padding: 1em;
}
</style>
