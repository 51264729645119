<template>
  <div class="form" :id="'step-' + stepIndex + 'substeps-exp-time-box'">
    <a-typography-text class="label" :id="substep + '-exp-time-text'">{{
      substep
    }}</a-typography-text>
    <a-input
      :id="substep + '-exp-time-input'"
      placeholder="Expected Time"
      :value="stepAvgTime(substep)"
      @change="handleStepAvgTimeChange"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  props: ['processIndex', 'stepIndex', 'substepIndex', 'substep'],
  computed: {
    ...mapGetters(['stepAvgTime']),
  },
  methods: {
    ...mapActions(['changeStepAvgTime']),
    handleStepAvgTimeChange(e) {
      const { value } = e.target;
      if (Number.isNaN(Number(value))) return;
      this.changeStepAvgTime({ step: this.substep, time: value });
    },
  },
};
</script>
<style scoped>
.form {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.label {
  color: #34495e;
  font-size: 0.875em;
  text-align: end;
  margin-right: 10px;
  width: 80%;
}

.form-control {
  height: 30.44px;
  width: 65%;
  font-size: 0.875em;
}
</style>
