<template>
  <div v-if="showSignalTower">
   
    <a-modal
    id="signal-tower-modal"
    :visible="showSignalTower"
    centered
    destroy-on-close
    closable
    :footer="null"
    title="Signal Tower Configuration"
    width="60vw"
    @cancel="hideSignalTowerComponent"
  >
    <signal-tower :device="selectedDeviceSerialNumber" />
  </a-modal>
  </div>
  <a-table
    v-show="!showSignalTower"
    :columns="columns"
    :data-source="activeDevicesList"
    :scroll="{ x: true }"
    :pagination="{ position: ['bottomCenter'] }"
    id="dep-model-active-devices-table"
  >
    <template #emptyText>
      <div
        style="height: 40vh"
        class="d-flex flex-column align-items-center justify-content-center"
        id="dep-model-no-devices"
      >
        <laptop-outlined style="fontsize: 40px" />
        <span class="mt-2">No Active Device Found</span>
      </div>
    </template>

    <template #headerCell="{ title }" :id="'dep-model' + title + '-hdcell'">
      <div class="text-center">{{ title }}</div>
    </template>

    <template #bodyCell="{ record, column, index }">
      <div
        class="text-center"
        v-if="column.dataIndex === 'deviceId'"
        :id="'dep-model-deviceId-' + index"
      >
        {{ record.display_name || record.serial_number }}
      </div>

      <div class="text-center" v-if="column.dataIndex === 'action'">
        <a-button
          class="my-1"
          type="primary"
          @click="showSignalTowerComponent(record)"
          :id="'dep-model-task-schedule-button-' + index"
        >
          View Singal Tower Configuration
        </a-button>
      </div>
    </template>
  </a-table>
</template>

<script>
import SignalTower from './SignalTower.vue';
import { useActiveDevices } from '../../RecordData/composables/useActiveDevices';

export default {
  components: { SignalTower },
  inject: ['toast'],
  setup() {
    const organization = localStorage.getItem('organization');
    const {
      activeDevicesList,
    } = useActiveDevices();
   
    return {
      activeDevicesList,
      organization
    };
  },

  data() {
    return {
      showSignalTower: false,
      selectedDeviceSerialNumber: null,
      organization: localStorage.getItem('organization'),
      columns: [
        {
          title: 'Device ID',
          dataIndex: 'deviceId',
          width: '10%',
          align: 'center',
        },
        {
          title: 'Action',
          dataIndex: 'action',
          width: '10%',
          align: 'center',
        },
      ],
    };
  },
  methods: {
    showSignalTowerComponent(record) {
      this.selectedDeviceSerialNumber = record.Serial_number;
      this.showSignalTower = true;
    },
    hideSignalTowerComponent() {
      this.showSignalTower = false;
      this.selectedDeviceSerialNumber = null;
    }
  },
};
</script>

<style></style>
