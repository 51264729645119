<template>
  <div class="h-100 d-flex flex-column">
    <a-card
      v-for="(process, processIndex) in taskProcesses"
      :key="process.name"
      class="process-container"
      hoverable
      :title="process.name"
      :bordered="false"
      :body-style="{
        flexGrow: '1',
        height: '1px',
        overflowY: 'auto',
        overflowX: 'hidden'
      }"
    >
      <a-table
        :columns="columns"
        :data-source="data"
        :pagination="false"
        :size="small"
      >
        <template #headerCell="{ column }">
          <template v-if="column.key === 'substep'">
            <span>
              <smile-outlined />
              Substep
            </span>
          </template>
        </template>

        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'substep'">
            <a>
              {{ record.substep }}
            </a>
          </template>
          <template v-else-if="column.key === 'anchor'">
            <span v-if="record.key != 0">
              <a-switch
                :checked="record.anchor == 'false' ? false : true"
                @change="value => setAnchorStep(record.key, value)"
              >
              </a-switch>
            </span>
            <span v-else>
              <a-switch :checked="false"> </a-switch>
            </span>
          </template>
          <template v-else-if="column.key === 'dependent'">
            <span>
              <a-select
                :value="dependents[record.key]"
                @change="value => setDependentSteps(record.key, value)"
                :options="setDependentStepOption"
                placeholder="Please select"
                style="width: 350px"
              >
                <template #suffixIcon>
                  <CloseOutlined
                    style="color: red;"
                    @click="removeOption(record.key)"
                  />
                </template>
              </a-select>
            </span>
          </template>
          <template v-else-if="column.key === 'similarStep'">
            <span v-if="record.key != 0">
              <a-select
                @change="
                  value =>
                    setSimilarStepAssociation(record.key, record.substep, value)
                "
                :value="similarSteps[record.key]"
                :options="setSimilarStepOption(record.substep)"
                mode="tags"
                placeholder="Please select"
                style="width: 300px"
              ></a-select>
            </span>
          </template>
        </template>
      </a-table>
      <template #actions>
        <div>
          <a-button
            type="primary"
            class="float-right mr-5"
            :loading="loading"
            @click="generateRelationObjectJson()"
          >
            Save
          </a-button>
        </div>
      </template>
    </a-card>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import StepObjects from './StepObjects.vue';
import { objectAnnotationModes } from 'src/config/task-steps-objects.js';
import { ref } from 'vue';
import { deepClone } from 'src/utils/task';

import {
  CheckOutlined,
  PlusOutlined,
  CloseOutlined
} from '@ant-design/icons-vue';

const columns = [
  {
    name: 'Substep',
    dataIndex: 'substep',
    key: 'substep'
  },
  {
    title: 'Anchor',
    dataIndex: 'anchor',
    key: 'anchor'
  },
  {
    title: 'Dependent',
    dataIndex: 'dependent',
    key: 'dependent'
  },
  {
    title: 'Similar Step',
    key: 'similarStep',
    dataIndex: 'similarStep'
  }
];

const data = [
  {
    key: 0,
    substep: 'abc',
    anchor: 'false'
  }
];
export default {
  components: {
    StepObjects,
    PlusOutlined,
    CloseOutlined,
    CheckOutlined
  },
  inject: ['toast'],
  props: ['pipelineStep'],
  emits: ['nextStep'],

  setup() {
    return { objectAnnotationModes };
  },
  data() {
    return {
      expressions: {},
      loading: false,
      substepRelation: [],
      addRelation: false,
      is_anchor: false,
      is_verified: false,
      mark_associated_steps: false,
      last_anchor_index: -1,
      associatedSteps: [],
      verifiedSteps: [],
      data,
      columns,
      similarStepOption: [],
      dependents: [],
      similarSteps: [],
      dependentStepOption: []
    };
  },
  watch: {},
  computed: {
    ...mapGetters([
      'taskProcesses',
      'steps',
      'substeps',
      'stepsToIndexMapping',
      'annotationObjectJson',
      'stepsJsonData',
      'objectWarnings',
      'taskObjects'
    ]),
    setSimilarStepOption() {
      var options = this.similarStepOption;
      console.log('options', options);
      return function(substep) {
        let similar_step = [];
        if (options != null && options != 'undefined') {
          options.forEach(step => {
            if (step.value != substep) {
              similar_step.push(step);
            }
          });
          similar_step.splice(0, 1);
          return similar_step;
        }
      };
    },

    staticObjectOptions() {
      return this.taskObjects
        .filter(({ is_static }) => is_static === true)
        .map(({ id, name, is_3d }) => ({
          value: name,
          label: name,
          key: id,
          is_3d: is_3d
        }));
    },

    setDependentStepOption() {
      let dependentStepOption = [];

      this.data.forEach(val => {
        if (val.anchor == 'true') {
          dependentStepOption.push({ value: val.substep });
        }
      });
      return dependentStepOption;
    },
    nonStaticObjectOptions() {
      return this.taskObjects
        .filter(({ is_static }) => is_static !== true)
        .map(({ id, name, is_3d }) => ({
          value: name,
          label: name,
          key: id,
          is_3d: is_3d
        }));
    },
    isDisable() {
      const steps = Object.keys(this.objectWarnings);
      if (!steps.length) return false;
      return steps.some(stepIndex => this.objectWarnings[stepIndex] === true);
    }
  },

  beforeMount() {
    this.data = [];
    this.dependents = [];
    this.similarSteps = [];
    this.changeAnnotationObjectTab(objectAnnotationModes['annotation']);
    this.setAnnotationObjectJson();

    console.log('stepData', this.stepsJsonData);

    this.taskProcesses.forEach(process => {
      this.expressions[process.name] = {};
      let stepCount = 0;
      process['steps'].forEach((step, stepIndex) => {
        step['substeps'].forEach((substep, subindex) => {
          this.similarStepOption.push({ value: substep });
          this.dependents.push([]);
          this.similarSteps.push([]);
          this.data.push({
            key: stepCount,
            substep: substep,
            anchor: 'false'
          });
          stepCount += 1;
        });
      });
    });
    console.log('ABC', this.dependents);
    let key = Object.keys(this.stepsJsonData)[0];
    if (key !=null && this.stepsJsonData[key].hasOwnProperty('step_relations')) {
      this.generateRelationFromStepData();
    }
  },

  methods: {
    ...mapActions([
      'setAnnotationObjectJson',
      'changeAnnotationObjectTab',
      'setStepsJsonData',
      'createStepsJsonData'
    ]),
    removeOption(key) {
      this.dependents[key] = [];
    },

    consecutiveSimilarSteps(key, value) {
      let isConsecutive = false;
      if (key - 1 > -1) {
        if (this.data[key - 1].substep == value) {
          isConsecutive = true;
        }
      }
      if (key + 1 < this.similarSteps.length) {
        if (this.data[key + 1].substep == value) {
          isConsecutive = true;
        }
      }
      return isConsecutive;
    },
    checkInnerConsecutiveSimilarStep(key, copySimilarSteps) {
      let indexOfSimilarStepValue = [];
      let similarStepValue = copySimilarSteps[key];
      let relationSteps = [...similarStepValue];
      let isConsecutive = false;

      for (let i = 0; i < similarStepValue.length; i++) {
        for (let j = 0; j < this.data.length; j++) {
          if (similarStepValue[i] == this.data[j].substep) {
            indexOfSimilarStepValue.push(j);
          }
        }
      }

      for (let i = 0; i < indexOfSimilarStepValue.length; i++) {
        relationSteps = [
          ...relationSteps,
          ...copySimilarSteps[indexOfSimilarStepValue[i]]
        ];
      }
      relationSteps = [...new Set(relationSteps)];
      console.log(indexOfSimilarStepValue, relationSteps);

      for (let i = 0; i < indexOfSimilarStepValue.length; i++) {
        for (let j = 0; j < relationSteps.length; j++) {
          isConsecutive = this.consecutiveSimilarSteps(
            indexOfSimilarStepValue[i],
            relationSteps[j]
          );
          if (isConsecutive) {
            break;
          }
        }
        if (isConsecutive) {
          break;
        }
      }

      // for (let i = 0; i < indexOfSimilarStepValue.length; i++) {
      //   let val = indexOfSimilarStepValue[i];
      //   isConsecutive = this.consecutiveSimilarSteps(val[0], val[1]);
      //   if (isConsecutive) {
      //     break;
      //   }
      // }
      return isConsecutive;
    },

    setSimilarStepAssociation(key, substep, value) {
      if (this.similarSteps[key].length < value.length) {
        let newValue = null;
        for (let j = 0; j < value.length; j++) {
          let isValueFound = false;
          for (let i = 0; i < this.similarSteps[key].length; i++) {
            if (this.similarSteps[key][i] == value[j]) {
              isValueFound = true;
              break;
            }
          }
          if (!isValueFound) {
            newValue = value[j];
            break;
          }
        }
        if (this.consecutiveSimilarSteps(key, newValue)) {
          this.toast.error('consecutive steps cannot be similar steps');
          return;
        }
        let uniqueSimilarSteps = [...new Set([...value, substep])];
        let indexsToBeUpdated = [];

        let allSelectedValue = [...uniqueSimilarSteps];
        for (let i = 0; i < value.length; i++) {
          let newValueIndex = -1;
          for (let j = 0; j < this.data.length; j++) {
            if (this.data[j].substep == value[i]) {
              newValueIndex = j;
            }
          }
          allSelectedValue = [
            ...new Set([
              ...allSelectedValue,
              ...this.similarSteps[newValueIndex]
            ])
          ];
        }
        for (let i = 0; i < allSelectedValue.length; i++) {
          let newValueIndex = -1;
          for (let j = 0; j < this.data.length; j++) {
            if (this.data[j].substep == allSelectedValue[i]) {
              newValueIndex = j;
            }
          }
          uniqueSimilarSteps = [
            ...new Set([
              ...uniqueSimilarSteps,
              ...this.similarSteps[newValueIndex]
            ])
          ];
          indexsToBeUpdated.push(newValueIndex);
        }

        let copySimilarSteps = [];
        this.similarSteps.forEach(step => {
          copySimilarSteps.push([...step]);
        });
        indexsToBeUpdated.forEach(index => {
          copySimilarSteps[index] = uniqueSimilarSteps;
        });
        copySimilarSteps[key] = uniqueSimilarSteps;
        if (this.checkInnerConsecutiveSimilarStep(key, copySimilarSteps)) {
          this.toast.error('consecutive steps cannot be similar steps');
          return;
        }
        indexsToBeUpdated.forEach(index => {
          this.similarSteps[index] = uniqueSimilarSteps;
        });
        this.similarSteps[key] = uniqueSimilarSteps;
      } else {
        let deletedIndex = key;
        let deletedValue = null;
        let oldSimilarSteps = [...this.similarSteps[key]];
        let oldSimilarStepsIndex = [];
        for (let j = 0; j < oldSimilarSteps.length; j++) {
          let isValueFound = false;
          for (let i = 0; i < value.length; i++) {
            if (oldSimilarSteps[j] == value[i]) {
              isValueFound = true;
              break;
            }
          }
          if (!isValueFound) {
            deletedValue = oldSimilarSteps[j];
            break;
          }
        }

        oldSimilarSteps.forEach(oldStep => {
          for (let i = 0; i < this.data.length; i++) {
            if (this.data[i].substep == oldStep) {
              oldSimilarStepsIndex.push(i);
            }
          }
        });
        console.log(oldSimilarStepsIndex , this.similarSteps , deletedValue);

        oldSimilarStepsIndex.forEach(oldStepindex => {
          let index = this.similarSteps[oldStepindex].indexOf(deletedValue);
          if (index > -1) {
            this.similarSteps[oldStepindex].splice(index, 1);
          }
        });

      }
    },
    setDependentSteps(key, value) {
      console.log('TEST', value);

      let anchor_index = -1;
      this.data.forEach((val, index) => {
        if (value.toString() == val.substep.toString()) {
          anchor_index = index;
        }
      });

      if (anchor_index > key) {
        let already_anchor = false;
        for (let i = anchor_index; i >= 0; i--) {
          if (this.dependents[i][0] == value) {
            already_anchor = true;
          }
        }
        if (already_anchor == false) {
          this.dependents[key] = [value];
        } else {
          this.toast.error(
            'one anchor dependent step can be selected from top'
          );
        }
      }
      if (anchor_index < key) {
        let already_anchor = false;
        for (let i = anchor_index; i < this.dependents.length; i++) {
          if (this.dependents[i][0] == value) {
            already_anchor = true;
          }
        }
        if (already_anchor == false) {
          this.dependents[key] = [value];
        } else {
          this.toast.error(
            'one anchor dependent step can be selected from bottom'
          );
        }
      }
    },
    setAnchorStep(key, anchor_value) {
      let value = anchor_value.toString();
      this.data[key].anchor = value.toString();
      if (value == 'false') {
        for (let i = 0; i < this.dependents.length; i++) {
          if (this.dependents[i][0] == this.data[key].substep.toString()) {
            this.dependents[i] = [];
          }
        }
      }
    },
    // step id , substep Name , verified , is_ancher , associated_ancher , associated_ancher_index
    addAnchor() {
      this.is_anchor = true;
      this.is_verified = false;
    },
    addVerifiedSteps() {
      if (this.mark_associated_steps) return;
      this.is_verified = true;
      this.is_anchor = false;
    },
    setAnchor(substep, stepIndex, substepIndex) {
      const prevVal = this.substepRelation[stepIndex][substepIndex];
      this.substepRelation[stepIndex][substepIndex] = {
        verified:
          prevVal != null && prevVal.verified != null ? prevVal.verified : null,
        is_anchor: true,
        associated_anchor_index:
          prevVal != null && prevVal.associated_anchor_index != null
            ? prevVal.associated_anchor_index
            : null
      };
      this.mark_associated_steps = true;
      this.is_anchor = false;
      this.last_anchor_index = substepIndex;
    },
    setStepsRelation() {
      if (this.is_verified) {
        console.log(this.verifiedSteps);
        this.verifiedSteps.forEach((step, stepIndex) => {
          step.forEach((value, substepIndex) => {
            const prevVal = this.substepRelation[stepIndex][substepIndex];
            this.substepRelation[stepIndex][substepIndex] = {
              verified: value,
              is_anchor:
                prevVal != null && prevVal.is_anchor != null
                  ? prevVal.is_anchor
                  : null,
              associated_anchor_index:
                prevVal != null && prevVal.associated_anchor_index != null
                  ? prevVal.associated_anchor_index
                  : null
            };
          });
        });
        this.is_verified = false;
      } else if (this.mark_associated_steps) {
        this.associatedSteps.forEach((step, stepIndex) => {
          step.forEach((value, substepIndex) => {
            const prevVal = this.substepRelation[stepIndex][substepIndex];
            this.substepRelation[stepIndex][substepIndex] = {
              verified:
                prevVal != null && prevVal.verified != null
                  ? prevVal.verified
                  : null,
              is_anchor:
                prevVal != null && prevVal.is_anchor != null
                  ? prevVal.is_anchor
                  : null,
              associated_anchor_index:
                prevVal != null && prevVal.associated_anchor_index != null
                  ? [...prevVal.associated_anchor_index, this.last_anchor_index]
                  : [this.last_anchor_index]
            };
          });
        });
        this.associatedSteps = [];
        this.mark_associated_steps = false;
      } else {
        // error
      }
      console.log('Relation', this.substepRelation);
    },
    async generateRelationObjectJson() {
      this.loading = true;

      let stepdata = this.generateRelation();
      this.setStepsJsonData(stepdata);
      await this.createStepsJsonData(stepdata);
      this.toast.success('Relation Saved');
      this.loading = false;
    },

    validateAnnotationObjects() {
      const steps_data = this.annotationObjectJson['annotation'];
      return Object.values(steps_data).some(
        ({ static_object, non_static_object }) =>
          static_object && !non_static_object.length
      );
    },

    generateRelationFromStepData() {
      let stepdata = this.stepsJsonData;
      for (var key of Object.keys(stepdata)) {
        let anchorRelation = stepdata[key]['step_relations']['anchor-relation'];
        let similarRelation =
          stepdata[key]['step_relations']['similar-relation'];

        // dependents
        let dependent = [];
        anchorRelation['key_steps'].forEach(keystep => {
          for (let i = 0; i < this.similarStepOption.length; i++) {
            if (i.toString() === keystep.toString()) {
              dependent.push(this.similarStepOption[i].value);
            }
          }
        });

        // similarsteps
        let similarstep = [];
        similarRelation['key_steps'].forEach(keystep => {
          for (let i = 0; i < this.similarStepOption.length; i++) {
            if (i.toString() === keystep.toString()) {
              similarstep.push(this.similarStepOption[i].value);
            }
          }
        });

        // set anchor step
        this.data.forEach((value, index) => {
          dependent.forEach(dept => {
            if (dept == value.substep) {
              this.data[index]['anchor'] = 'true';
            }
          });
        });
        this.dependents[parseInt(key)] = dependent;
        this.similarSteps[parseInt(key)] = similarstep;
      }
    },

    generateRelation() {
      let stepdata = this.stepsJsonData;
      for (var key of Object.keys(stepdata)) {
        let anchorRelation = {};
        let similarRelation = {};
        let previousRelation = {};

        let is_anchor = 'false';
        let substepName = '';

        let before = [];
        let after = [];
        let dependentKeySteps = [];
        let similarKeySteps = [];

        this.data.forEach(value => {
          if (key.toString() === value.key.toString()) {
            is_anchor = value.anchor;
            substepName = value.substep;
          }
        });
        // if (is_anchor != 'true') {
        let dependent = this.dependents[parseInt(key)][0];
        let dependentKey = -1;
        for (let i = 0; i < this.similarStepOption.length; i++) {
          if (this.similarStepOption[i].value == dependent) {
            dependentKeySteps.push(i.toString());
            dependentKey = i;
          }
        }
        console.log('dependent', dependent);
        if (dependentKey > -1 && key.toString() > dependentKey.toString()) {
          after.push(key);

          for (let i = parseInt(key) - 1; i >= 0; i--) {
            if (this.dependents[i][0] == dependent) {
              before.push(i.toString());
              break;
            }
          }
        }
        if (dependentKey > -1 && key.toString() < dependentKey.toString()) {
          before.push(key);
          for (let i = parseInt(key) + 1; i < this.dependents.length; i++) {
            if (this.dependents[i][0] == dependent) {
              after.push(i.toString());
              break;
            }
          }
        }
        // }

        this.similarSteps[parseInt(key)].forEach(similarStep => {
          for (let i = 0; i < this.similarStepOption.length; i++) {
            if (this.similarStepOption[i].value == similarStep) {
              similarKeySteps.push(i.toString());
            }
          }
        });

        anchorRelation = {
          key_steps: dependentKeySteps,
          before_anchor: before,
          after_anchor: after
        };
        similarRelation = {
          key_steps: similarKeySteps,
          before_anchor: [],
          after_anchor: []
        };
        previousRelation = {
          key_steps: [],
          before_anchor: [],
          after_anchor: []
        };
        stepdata[key]['step_relations'] = {
          'anchor-relation': anchorRelation,
          'similar-relation': similarRelation
        };
      }
      return stepdata;
    }
  }
};
</script>

<style scoped>
.process-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>
