<template>
  <div>
    <a-row v-if="task_name" :gutter="[8, 8]">
      <a-col :span="4">
        <p style="marginTop:19px;fontWeight:bold">{{ task_name }}</p>
      </a-col>
      <a-col :span="4">
        <a-tooltip title="Scan this Qrcode to switch task">
          <QrcodeVue
            v-if="task_id"
            ref="TaskQRCode"
            :value="JSON.stringify(task_json)"
            :size="70"
            level="M"
            :id="task_id + '-QR'"
          />
        </a-tooltip>
      </a-col>
    </a-row>
    <a-table
      v-if="stepsList && isLoadingStepList"
      :columns="columns"
      :data-source="stepsList"
      bordered
      :scroll="{ y: '50vh', x: '800px' }"
    >
      <template #title>
        <a-typography-title :level="5">
          Barcode Steps: &nbsp;
          <a-tag color="blue">{{ stepsList.length }}</a-tag>
        </a-typography-title>
      </template>
      <template #bodyCell="{ column,record }">
        <template v-if="column.dataIndex === 'barcode'">
          <div>
            <a-space class="my-2" v-if="record.barcode || record.barcode_id">
              <a-input-group
                class="w-100 d-flex justify-content-between"
                v-if="record.enableUpdateButton"
              >
                <a-input
                  @click="enableUpdateButtonForBarcode"
                  @change="updatedQrCode($event, record)"
                  v-model:value="record.barcode"
                />
                <a-button
                  class="ml-2"
                  :disabled="!enableUpdateButton"
                  type="primary"
                  @click="renameBarcode(record)"
                >
                  <edit-outlined /> Update
                </a-button>
              </a-input-group>
            </a-space>
            <a-space v-else>
              <a-input-group class="w-100 d-flex justify-content-between">
                <a-input
                  v-model:value="record.inputValue"
                  placeholder="Add Barcode"
                  @change="newQrCode($event, record)"
                />
                <a-button
                  class="ml-2 justify-content-between"
                  type="primary"
                  @click="addBarcode(record)"
                >
                  <plus-outlined class="ml-2 mr-1" /> Add
                </a-button>
              </a-input-group>
            </a-space>
          </div>
        </template>

        <template v-if="column.dataIndex === 'QrCode'">
          <div class="barcodeIcon">
            <span v-if="record.barcode">
              <div v-if="!record.showBarCode">
                <QrcodeVue
                  ref="QRCode"
                  :value="record.barcode"
                  :size="150"
                  level="M"
                  :id="record.barcode + '-QR'"
                />
              </div>
              <div v-if="record.showBarCode">
                <barcode
                  ref="QRCode"
                  :value="record.barcode"
                  :options="vueBarcodeOptions"
                  :id="record.barcode + '-Barcode'"
                  style="width: 250px; height: 100px"
                ></barcode>
              </div>
            </span>
            <span v-else-if="record.inputValue">
              <div v-if="!record.showBarCode">
                <QrcodeVue
                  ref="QRCode"
                  :value="record.inputValue"
                  :size="150"
                  level="M"
                  :id="record.barcode + '-QR'"
                />
              </div>

              <div v-if="record.showBarCode">
                <barcode
                  ref="QRCode"
                  :value="record.inputValue"
                  :options="vueBarcodeOptions"
                  :id="record.inputValue + '-Barcode'"
                  style="width: 250px; height: 100px"
                ></barcode>
              </div>
            </span>
            <span v-else>
              <p>Barcode not available</p>
            </span>
          </div>
        </template>

        <template v-if="column.dataIndex === 'actions'">
          <div class="actions">
            <a-dropdown :trigger="['click']">
              <a-button class="ml-2">
                <template #icon>
                  <MenuOutlined class="menu-icon" />
                </template>
              </a-button>
              <template #overlay>
                <a-menu>
                  <a-menu-item
                    @click="toggleBarcode(record)"
                    v-bind:disabled="!record.barcode"
                  >
                    {{ record.showBarCode ? 'Show QrCode' : 'Show Barcode' }}
                  </a-menu-item>
                  <a-menu-item
                    @click="handleRemoveBarcode(record.substepIndex)"
                    v-bind:disabled="!record.barcode"
                  >
                    {{
                      record.showBarCode ? 'Remove Barcode' : 'Remove QrCode'
                    }}
                    <!-- Remove QR Code -->
                  </a-menu-item>

                  <a-menu-item
                    @click="printQRCode(record)"
                    v-bind:disabled="!record.barcode"
                  >
                    {{ record.showBarCode ? 'Print Barcode' : 'Print QrCode' }}
                    <!-- Print QR Code -->
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </div>
        </template>
      </template>
    </a-table>

    <a-table class="w-100" :columns="columns" bordered v-else> </a-table>
  </div>
</template>
<script>
import { useToast } from 'vue-toastification';
import httpClient from 'src/service/httpClient.js';
import parseTaskList from '../../../../shared/Helpers/parseTaskList';
import { mapActions, mapGetters } from 'vuex';
import handleDDSpace from 'src/mixins/handleSpace';
import {
  DeleteOutlined,
  CloseOutlined,
  MenuOutlined,
  PlusOutlined,
  EditOutlined
} from '@ant-design/icons-vue';
import BarcodeService from 'src/services/barcode';
import QRCodeVue3 from 'qrcode-vue3';
import printJS from 'print-js';
import QrcodeVue from 'qrcode.vue';
import Barcode from '@chenfengyuan/vue-barcode';
import html2canvas from 'html2canvas';

export default {
  mixins: [handleDDSpace],
  components: {
    DeleteOutlined,
    CloseOutlined,
    MenuOutlined,
    PlusOutlined,
    EditOutlined,
    QRCodeVue3,
    QrcodeVue,
    Barcode,
    VNodes: (_, { attrs }) => {
      return attrs.vnodes;
    }
  },
  props: ['selectedTask', 'selectedTaskName'],

  setup() {
    const toast = useToast();
    return { toast };
  },

  data() {
    return {
      columns: [
        {
          title: 'Step Name',
          dataIndex: 'name',
          align: 'center',
          width: '8%'
        },
        {
          title: 'Barcode Data',
          dataIndex: 'barcode',
          width: '30%',
          align: 'center',
          width: '12%'
        },
        {
          title: 'Barcode',
          dataIndex: 'QrCode',
          align: 'center',
          width: '20%'
        },
        {
          title: '',
          dataIndex: 'actions',
          width: '5%',
          align: 'center'
        }
      ],
      // vueBarcodeOptions: {
      //   format: 'CODE128',
      //   displayValue: true,
      // },
      vueBarcodeOptions: {
        format: 'CODE128',
        width: 4,
        height: 100,
        displayValue: false
      },
      current_task: {},
      stepsList: [],
      task_name: '',
      task_id: null,
      task_json: {
        task_id: '',
        task_name: '',
        organization: localStorage.getItem('organization')
      },
      currentStepList: {},
      task_list: [],
      options: [],
      isLoadingTasks: false,
      isTaskDetailsLoading: false,
      showUploadModal: false,
      isLoadingStepList: false,
      stepBarcodes: [],
      allbarcodes: [],
      changeFound: false,
      selectedBarcode: '',
      // selectedTask: null,
      selectedStepIndex: null,
      showForm: false,
      selectedRowData: {},
      addNewBarcode: false,
      updateBarcode: false,
      newBarcodeValue: '',
      updatedBarcodeValue: '',
      enableUpdateButton: false
    };
  },

  computed: {
    ...mapGetters([
      'associateBarcodes',
      'isFetchingAssociateBarcodes',
      'isUpsertingAssociateBarcodes',
      'allBarcodes',
      'isFetchingBarcodes',
      'isRemovingAssociatedBarcode',
      'isTourRunning',
      'organization',
      'user',
      'taskName',
      'taskDetails',
      'negativeSteps',
      'taskProcesses'
    ]),
    barcodeOptions() {
      let usedBarcodes = this.stepBarcodes.map(el => el.barcode_id);
      let tempBarcodes = this.allbarcodes.map(el => ({
        value: el.id,
        label: el.barcode_value
      }));
      return tempBarcodes.filter(item => !usedBarcodes.includes(item.value));
    }
  },

  async created() {
    // this.getTaskList();
    await this.getAllbarcodes();
    this.task_id = this.selectedTask;
    this.getTaskData(this.selectedTask);
  },
  mounted() {
    this.options = this.task_list.map(el => ({
      value: el.id,
      label: el.taskName
    }));
  },
  beforeUnmount() {
    clearInterval(this.pollingRecords);
    // this.clearTaskDetails();
  },

  watch: {
    task_id: function(value) {
      this.getTaskData(value);
    },
    stepBarcodes(barcodes) {
      this.indexToBarcodeMapping = barcodes.reduce((res, el) => {
        res[el.step_index] = el;
        return res;
      }, {});
    }
  },

  methods: {
    ...mapActions([
      'removeAssociatedBarcode',
      'createAssociateBarcodes',
      'updateAssociateBarcodes',
      'changeRouteConfig',
      'changeRoute',
      'stopTour',
      'fetchTaskDetails',
      'setSelectedLanguage',
      'clearTaskDetails',
      'setIndexToStepsMapping',
      'setTaskNameAndProcess'
    ]),
    updatedQrCode(event) {
      this.updatedBarcodeValue = event.target.value;
    },
    newQrCode(event, record) {
      this.newBarcodeValue = event.target.value;
      record.inputValue = event.target.value;
    },
    async renameBarcode(record) {
      if (!record.barcode) {
        return this.toast.warning('Please enter barcode value');
      }
      this.enableUpdateButton = false;
      const data = {
        id: record.barcode_id,
        new_barcode_value: record.barcode,
        task: this.task_id,
        step_index: record?.substepIndex
      };
      await this.updateBarcodeValue(data, record);
    },
    enableAddButtonForBarcode(record) {
      record.enableAddButton = true;
    },
    enableUpdateButtonForBarcode() {
      this.enableUpdateButton = true;
    },
    async addBarcode(record) {
      if (this.newBarcodeValue != '') {
        const data = {
          new_barcode_value: this.newBarcodeValue,
          step_index: record.substepIndex,
          task: record.task
        };
        await this.createQrCode(data, record);
      }
    },
    addBarcodesToSteps(barcodes, record = {}) {
      if (record.step_index) {
        let step_barcode = barcodes.find(
          ele => ele.step_index === record.step_index
        );
        this.stepsList = this.stepsList.map((obj, index) => {
          if (step_barcode.step_index === index) {
            return {
              ...obj,
              barcode: step_barcode ? step_barcode.barcode : '',
              barcode_id: step_barcode ? step_barcode.barcode_id : '',
              task: this.task_id,
              enableAddButton: step_barcode ? false : true,
              enableUpdateButton: step_barcode ? true : false,
              inputValue: '',
              showBarCode: true
            };
          }
        });
      } else {
        this.stepsList = this.stepsList.map((obj, index) => {
          let step_barcode = barcodes.find(ele => ele.step_index === index);
          return {
            ...obj,
            barcode: step_barcode ? step_barcode.barcode : '',
            barcode_id: step_barcode ? step_barcode.barcode_id : '',
            task: this.task_id,
            enableAddButton: step_barcode ? false : true,
            enableUpdateButton: step_barcode ? true : false,
            inputValue: '',
            showBarCode: true
          };
        });
      }
    },

    async getAllbarcodes() {
      const [error, data] = await BarcodeService.fetchBarcodes();
      if (data) {
        this.allbarcodes = data;
      }
    },

    async getAssociateBarcode(taskId) {
      const [error, data] = await BarcodeService.fetchAssociateBarcodes(taskId);
      if (data) {
        this.addBarcodesToSteps(data);
        this.stepBarcodes = data;
      }
    },

    getTaskData(taskId) {
      this.isTaskDetailsLoading = true;
      this.isLoadingStepList = false;
      httpClient.getData('organization/task/' + taskId + '/').then(data => {
        const temp = parseTaskList(data.task_detail);
        this.stepsList = temp.filter(obj => {
          if (obj.type === 'sub-step') return obj;
        });
        this.currentStepList = temp;
        this.current_task = temp;
        this.task_name = data.taskName;
        this.task_json['task_id'] = this.task_id;
        this.task_json['task_name'] = this.task_name;
        this.isLoadingStepList = true;
        this.getAssociateBarcode(this.task_id);
      });
      this.isTaskDetailsLoading = false;
    },

    async getTaskList() {
      this.isLoadingTasks = true;
      const list = await httpClient.get('organization/task/');
      this.task_list = list;
      this.options = list.map(el => ({
        value: el.id,
        label: el.taskName
      }));
      this.task_id = this.selectedTask;
      this.isLoadingTasks = false;
    },
    handleSelectedBarcode(event, data) {
      this.selectedStepIndex = data?.substepIndex;
      this.selectedBarcode = event;
      let payload = {
        task: this.task_id,
        barcode: this.selectedBarcode,
        step_index: this.selectedStepIndex
      };
      let prev = this.indexToBarcodeMapping[this.selectedStepIndex];
      if (prev) {
        this.updateAssociateBarcodes({ id: prev.id, payload }).then(res => {
          this.getAssociateBarcode(this.task_id);
        });
      } else {
        this.createAssociateBarcodes(payload).then(res => {
          this.getAssociateBarcode(this.task_id);
        });
      }
    },
    async printQRCode(record) {
      let element = null;
      record.showBarCode
        ? (element = document.getElementById(record.barcode + '-Barcode'))
        : (element = document.getElementById(record.barcode + '-QR'));
      const canvas = await html2canvas(element);
      const image = canvas.toDataURL('image/png');

      printJS({
        printable: image,
        type: 'image',
        header: `<div style="font-family: 'Montserrat', sans-serif;">
          <h2>Task Name:
            <span style="font-weight: 500">${this.task_name}</span>
          </h2>
          <h3>Step Name:
            <span style="font-weight: 500">${record.name}</span>
          </h3>
        </div>`,
        imageStyle: 'width:60%;'
      });
    },
    handleRemoveBarcode(qrId) {
      let barcode = this.indexToBarcodeMapping[qrId];
      this.removeAssociatedBarcode(barcode?.id).then(res => {
        if (res) {
          this.getAssociateBarcode(this.task_id);
          const [error, data] = BarcodeService.deleteBarcode(
            barcode?.barcode_id
          );
          if (error) {
            return this.toast.error('Failed to delete barcode');
          }
        }
      });
    },
    barcodelengthError(error, record) {
      record.enableUpdateButton = true;
      record.enableAddButton = false;
      return this.toast.error(error.response.data.error);
    },
    errorBarcodeExists(error, record) {
      record.enableUpdateButton = true;
      record.enableAddButton = false;
      let id = '';
      this.toast.error(error.response.data.error);
      setTimeout(() => {
        id = this.toast.info('Please enter a unique barcode value!');
      }, 3000);
      this.toast.dismiss(id);
    },
    async createQrCode(payload, record) {
      const [error, data] = await BarcodeService.createCustomBarcode(payload);
      if (error) {
        record.inputValue = '';
        this.newBarcodeValue = '';
        if (error.response.status === 409) {
          this.errorBarcodeExists(error, record);
          return;
        } else if (error.response.status === 400) {
          this.barcodelengthError(error, record);
          return;
        }
        record.enableUpdateButton = false;
        record.enableAddButton = true;
        return this.toast.error('Error while creating barcode!');
      }
      record.enableUpdateButton = true;
      record.enableAddButton = false;
      record.barcode_id = data.id;
      record.barcode = this.newBarcodeValue;
      // this.handleSelectedBarcode(data.id, record);
      return this.toast.success('Barcode data added successfully.');
    },

    async updateBarcodeValue(payload, record) {
      const [error, data] = await BarcodeService.createCustomBarcode(payload);
      if (error) {
        if (error.response.status === 409) {
          this.errorBarcodeExists(error, record);
          return;
        } else if (error.response.status === 400) {
          this.barcodelengthError(error, record);
          return;
        }
        record.enableUpdateButton = true;
        record.enableAddButton = false;
        return this.toast.error('Error while updating barcode!');
      }
      await this.getAssociateBarcode(this.task_id);
      this.handleSelectedBarcode(
        this.indexToBarcodeMapping[record.substepIndex].barcode_id,
        record
      );
      return this.toast.success('Barcode data updated successfully.');
    },
    toggleBarcode(record) {
      record.showBarCode = !record.showBarCode;
    }
  }
};
</script>
<style scoped>
.Disconnected {
  color: rgb(255, 0, 0);
}
.Connected {
  color: rgb(76, 175, 80);
}
.row {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
</style>
