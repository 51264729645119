<template>
  <div id="add-translation-form">
    <a-input
      placeholder="Translation Language"
      v-model:value="language"
      style="margin-bottom: 1em"
      id="language-input"
    />

    <a-tag class="h1 mb-1 my-0" color="processing">{{ taskName }}</a-tag>
    <a-input
      :value="translatedTaskName"
      @change="(e) => setTranslatedTaskName(e.target.value)"
      placeholder="Task name"
      style="margin-bottom: 1em"
      id="translated-taskname-input"
    />

    <a-card size="small" title="Process(es)">
      <div class="process-container">
        <process-translation
          v-for="(process, processIndex) in taskProcesses"
          :key="processIndex"
          :processIndex="processIndex"
          :process="process.name"
        >
        </process-translation>
      </div>
      <div class="d-flex pt-2" id="delete-translation-popover">
        <a-popconfirm
          title="Are you sure? You want to delete this translation"
          ok-text="Yes"
          cancel-text="No"
          @confirm="handleDeleteTranslation"
        >
          <a-button
            danger
            :disabled="isAddingTranslation"
            v-if="isTranslationEdit"
            :loading="isDeletingTranslation"
            id="delete-translation-btn"
          >
            Delete Translation
          </a-button>
        </a-popconfirm>
        <a-space class="ml-auto">
          <a-button
            @click="cancelTranslation"
            :disabled="isAddingTranslation"
            id="cancel-translation-btn"
          >
            Cancel
          </a-button>
          <a-button
            type="primary"
            @click="addTaskTranslation"
            :disabled="!isDisabled"
            :loading="isAddingTranslation"
            id="save-translation-btn"
          >
            {{ `${isTranslationEdit ? 'Update' : 'Add'} Translation` }}
          </a-button>
        </a-space>
      </div>
    </a-card>
  </div>
</template>
<script>
import ProcessTranslation from './ProcessTranslation.vue';
import { mapActions, mapGetters } from 'vuex';
import { useToast } from 'vue-toastification';

export default {
  components: {
    ProcessTranslation,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      language: '',
      translatedTaskString: '',
      inputFlag: false,
    };
  },
  computed: {
    ...mapGetters([
      'taskProcesses',
      'processesTranslation',
      'taskName',
      'isAddingTranslation',
      'isTranslationEdit',
      'translatedTaskName',
      'translationLanguage',
      'isDeletingTranslation',
    ]),
    isDisabled() {
      return (
        this.language &&
        this.translatedTaskName &&
        this.processesTranslation.every(
          (p) =>
            !!p.name &&
            p.steps.every((s) => !!s.name && s.substeps.every((ss) => !!ss))
        )
      );
    },
  },
  created() {
    this.language = this.translationLanguage;
  },

  methods: {
    ...mapActions([
      'setProcesses',
      'setProcessesTranslation',
      'addTranslation',
      'cancelTranslation',
      'setTranslatedTaskName',
      'deleteTranslation',
    ]),
    handleDeleteTranslation() {
      this.language = '';
      this.deleteTranslation();
    },
    addTaskTranslation() {
      if (this.language === 'English') {
        this.toast.error('Translation Language should be other than English,');
        return;
      }
      this.addTranslation(this.language);
    },
  },
};
</script>
<style scoped>
.h1 {
  font-size: 16px;
}

label {
  width: 35%;
  color: #34495e;
}

.process-container {
  overflow-y: auto;
  height: 44vh;
}
</style>
